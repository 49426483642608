<script setup lang="ts">
import { captureException } from '@sentry/vue'
import type { UppyFile } from '@uppy/core'
import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import XHR from '@uppy/xhr-upload'
import { storeToRefs } from 'pinia'

import '@uppy/core/dist/style.min.css'
import '@uppy/drag-drop/dist/style.min.css'

import { useToast } from 'vue-toastification'
import type { ListVideoCategoryDto } from '~/api/gen'
import { AuthorStatus, MediaProjectType, VideoCategoryControllerApi } from '~/api/gen'
import { WizardStepNames } from '~/components/media-project-wizard/Wizard'
import { formatToGB } from '~/services/formatter'
import { getConfiguration, httpClient } from '~/axios'

const { t } = useI18n()
const userStore = useUserStore()
const router = useRouter()

const { user } = storeToRefs(userStore)

const state = reactive({
  uploadingConsent: false,
  categories: [] as ListVideoCategoryDto[],
})

const consentUpload = ref(null)

const api = new VideoCategoryControllerApi(getConfiguration(), undefined, httpClient)
let uppy = null as Uppy | null
onMounted(async () => {
  const categoriesResponse = await api.getAll()
  state.categories = categoriesResponse.data

  await userStore.updateAuthorData()
  uppy = new Uppy({
    restrictions: {
      allowedFileTypes: ['application/pdf'],
      maxFileSize: 10 * 1024 * 1024,
      maxNumberOfFiles: 1,
    },
  })

  if (userStore.user.needsWillRegistration) {
    let language
    if (userStore.user.language === 'de')
      language = await import('@uppy/locales/lib/de_DE')
    else
      language = await import('@uppy/locales/lib/en_US')

    uppy.setOptions({
      locale: language.default,
    })
    if (consentUpload.value)
      uppy.use(DragDrop, { id: 'drag-drop', target: `#${consentUpload.value.id}` })

    uppy.use(XHR, {
      endpoint: `${import.meta.env.VITE_API_BASE_URL}/api/author/${user.value.id}/signed-consent`,
      headers: (file) => {
        return { Authorization: `Bearer ${user.value.token}` }
      },
      fieldName: 'document',
    })

    uppy.on('complete', async (f) => {
      await userStore.updateAuthorData()
      uppy?.close()
    })
    uppy.on('restriction-failed', (file, error) => {
      useToast().error(t('errors.fileTooLargeOrNotPdf'))
    })

    uppy.on('upload-error', async (file, error, response) => {
      if (response?.status === 401 && !file!.meta.retry) {
        await useUserStore().updateToken(60)
        await uppy?.retryAll()
        file!.meta.retry = true
      }
      else {
        uppy?.cancelAll()
        state.uploadingConsent = false

        useToast().error(t('errors.oops'))
        captureException(error, {
          extra: {
            mimeType: file?.meta.type,
          },
          tags: {
            category: 'ConsentUploadError',
          },
        })
      }
    })
    uppy.on('file-added', async (file: UppyFile) => {
      state.uploadingConsent = true
      await uppy?.upload()
      state.uploadingConsent = false
    })
  }
})

onUnmounted(() => {
  uppy?.close()
})

const createNewDraft = async (category: ListVideoCategoryDto) => {
  await router.push({ path: '/media-project-draft/create', query: { category: category.id, categoryName: category.name, type: MediaProjectType.JoyfulOccasion } })
}

const error = ref<Error | null>(null)
onErrorCaptured((e: Error) => {
  error.value = e
  return true
})
</script>

<template>
  <div :class="user.isLoaded() ? 'mt-16 flex justify-center mx-auto' : 'hidden'" class="">
    <div class="mb-10">
      <div v-if="user.needsWillRegistration" class="mb-16">
        <div class="heading text-2xl mb-4">
          {{ t('sign-consent.heading') }}
        </div>

        <p class="mb-8" v-html="t('sign-consent.info')" />

        <div class="mb-8">
          <a class="btn" href="https://imma.video/public/AGB%20und%20Datenschutzerkl%C3%A4rung.pdf" target="consent">
            {{ t("sign-consent.download-signed-constent") }}
          </a>
        </div>
        <p class="mb-2" v-html="t('sign-consent.info-upload')" />

        <LoadingSpinner
          :loading="state.uploadingConsent"
        />

        <div id="consent-upload" ref="consentUpload" class="min-h-267px" />
      </div>

      <div v-if="user.authorStatus === AuthorStatus.SignatureValidation" class="mb-8 flex items-center">
        <i class="bg-blue w-12 h-12 i-carbon:information" />
        <p class="ml-4 text-blue">
          {{ t('sign-consent.verifying') }}
        </p>
      </div>

      <h1 class="heading mb-8">
        {{ t('intro.hi', { name: userStore.user.firstName }) }}
      </h1>

      <p class="mb-8">
        {{ t('video-categories.select-info') }}
      </p>
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-x-8 gap-y-8 mb-12">
        <ImmaCreateEventButton v-for="category in state.categories" :key="category.id" :category-name="category.name" :action="() => createNewDraft(category)">
          {{ t(`video-categories.${category.name}`, category.name) }}
        </ImmaCreateEventButton>
      </div>
      <!-- <div class="flex flex-wrap justify-center gap-x-8 gap-y-8 mb-12">
        <imma-card
          :heading="t('general.from-beyond')"
          :body="t('intro.from-beyond-info')"
          price=""
          :price-info="!user.isAfterDeathPackageActive ? `${t('pricing.first-after-death-video-info')}` : undefined"
          :primary-action-label="t('general.start-project')"
          @primary-action="startMediaProject('AfterDeath')"
        />
        <imma-card
          :heading="t('general.joyful-occasion')"
          :body="t('intro.joyful-occasion-info')"
          price=""
          :price-info="`${t('pricing.storage-info')}`"
          :primary-action-label="t('general.start-project')"
          @primary-action="startMediaProject('JoyfulOccasion')"
        />
      </div> -->

      <!-- <h2 class="heading">
        {{ t('global.storage') }}
      </h2>
      <div class="mb-8">
        <p v-if="user.storageSeats === 0" class="mb-2">
          {{ t('pricing.free-tier-active') }}
        </p>
        <p v-else class="mb-2">
          {{ t('pricing.storage-seats-used') }} {{ userStore.user.storageSeats }}
        </p>
        <p>
          {{ formatToGB(userStore.user.usedSpace ?? 0) }} / {{ formatToGB(userStore.user.totalSpace ?? 0) }} GB {{ t('global.used') }}
        </p>
      </div> -->
      <h2 class="heading">
        {{ t("global.recently-started") }}
      </h2>

      <div v-if="error" :error="error">
        {{ error.message }}
      </div>
      <Suspense>
        <RecentProjects />
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
</route>
